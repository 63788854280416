// Generated by Framer (a10584e)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Youtube as YouTube } from "https://framerusercontent.com/modules/NEd4VmDdsxM3StIUbddO/8aCGinfRQO68tQ3QF42d/YouTube.js";
const YouTubeFonts = getFonts(YouTube);

const enabledGestures = {Tn6crCxsU: {hover: true}};

const serializationHash = "framer-exhGs"

const variantClassNames = {Tn6crCxsU: "framer-v-1rjsmby"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.4, delay: 0, duration: 0.6, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, zEeYqpoGo: click ?? props.zEeYqpoGo} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, zEeYqpoGo, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Tn6crCxsU", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapbzbyg1 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (zEeYqpoGo) {const res = await zEeYqpoGo(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1rjsmby", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"Tn6crCxsU"} onTap={onTapbzbyg1} ref={refBinding} style={{backgroundColor: "var(--token-f213e283-24d0-40a3-a2dc-bca1da07b971, rgb(20, 20, 20))", borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24, ...style}} {...addPropertyOverrides({"Tn6crCxsU-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-ni7yrc"} data-framer-name={"content"} layoutDependency={layoutDependency} layoutId={"XT3LwRaMe"} style={{borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16}}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-10p5g6f-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"LapnP3DBT-container"} nodeId={"LapnP3DBT"} rendersWithMotion scopeId={"sHD_sSd9c"}><YouTube borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} height={"100%"} id={"LapnP3DBT"} isMixedBorderRadius={false} isRed layoutId={"LapnP3DBT"} play={"Off"} shouldMute style={{height: "100%", width: "100%"}} thumbnail={"High Quality"} topLeftRadius={0} topRightRadius={0} url={"https://www.youtube.com/watch?v=sZBAIlML3-U"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-exhGs.framer-12l4dx8, .framer-exhGs .framer-12l4dx8 { display: block; }", ".framer-exhGs.framer-1rjsmby { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 365px; justify-content: center; overflow: visible; padding: 10px; position: relative; width: 608px; }", ".framer-exhGs .framer-ni7yrc { flex: 1 0 0px; height: 100%; overflow: hidden; position: relative; width: 1px; will-change: var(--framer-will-change-override, transform); }", ".framer-exhGs .framer-10p5g6f-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-exhGs.framer-1rjsmby { gap: 0px; } .framer-exhGs.framer-1rjsmby > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-exhGs.framer-1rjsmby > :first-child { margin-left: 0px; } .framer-exhGs.framer-1rjsmby > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 365
 * @framerIntrinsicWidth 608
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ocx0_xg9z":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"zEeYqpoGo":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramersHD_sSd9c: React.ComponentType<Props> = withCSS(Component, css, "framer-exhGs") as typeof Component;
export default FramersHD_sSd9c;

FramersHD_sSd9c.displayName = "Cards / sucess story card";

FramersHD_sSd9c.defaultProps = {height: 365, width: 608};

addPropertyControls(FramersHD_sSd9c, {zEeYqpoGo: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramersHD_sSd9c, [{explicitInter: true, fonts: []}, ...YouTubeFonts], {supportsExplicitInterCodegen: true})